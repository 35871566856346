<template>
  <sweet-modal
    ref="modal"
    v-sweet-esc
    title="Select a Survey to include"
    @close="$emit('close')"
  >
    <div>
      <div v-if="surveys">
        <div class="h6 ml-2">
          Results
          <span
            v-if="surveys.length > 0"
            class="ml-2 text-muted small"
          >
            Click on Survey to add
          </span>
        </div>
        <div v-if="surveys.length > 0">
          <div
            v-for="survey in surveys"
            :key="survey.id"
            class="selectable-option rounded"
            @click="$emit('input', survey)"
          >
            {{ survey.title }}
          </div>
        </div>
        <div
          v-else
          class="h6 text-muted"
        >
          No surveys present.
        </div>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
import http from 'common/http';
import { SweetModal } from 'sweet-modal-vue';

export default {
  components: {
    SweetModal,
  },
  data() {
    return {
      surveys: [],
    };
  },
  methods: {
    fetchSurveys() {
      return (
        http
          .get(`/custom_surveys.json`)
          .then(res => {
            this.surveys = res.data.customSurveys;
          })
          .catch(() => {
            this.emitError('Sorry, there was an error while fetching surveys.');
          })
        );
    },
    open() {
      this.fetchSurveys();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.selectable-option {
  padding: 8px;
  &:hover {
    background-color: $themed-light;
  }
}
</style>
