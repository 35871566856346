<template>
  <div>
    <div>
      <input
        ref="input"
        class="hidden"
        type="text"
        name="department"
        :required="required"
        :value="value"
      >
      <multi-select
        tag-placeholder="Select Department"
        track-by="id"
        label="name"
        taggable
        :value="selectedDepartment"
        :multiple="false"
        :options="departments"
        :allow-empty="!required"
        @select="emitValue"
        @remove="removeDepartment"
        @open="fetchDepartments"
      />
      <span
        v-if="error"
        class="form-text small text-danger"
      >
        {{ error }}
      </span>
    </div>
  </div>
</template>
  
<script>
  import MultiSelect from 'vue-multiselect';
  import http from "common/http";
  import fieldViewerInput from 'mixins/field_viewer_input';
  import permissionsHelper from 'mixins/permissions_helper';
  import loggedIn from 'mixins/logged_in';

  export default {
    components: {
      MultiSelect,
    },
    mixins: [fieldViewerInput, permissionsHelper, loggedIn],
    props: {
      field: {
        type: Object,
        default: () => {},
      },
      value: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      slaFields: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        error: null,
        departments: [],
        selectedDepartment: null,
        showDepartmentModal: false,
        userDepartment: "",
      };
    },
    watch: {
      value() {
        this.fetchDepartments();
      },
    },
    methods: {
      onWorkspaceChange() {
        if (this.isLoggedIn) {
          this.loadUserDept().then(() => {
            this.fetchDepartments();
          });
        } else {
          this.fetchDepartments();
        }
      },
      emitValue(value) {
        this.error = null;
        this.$emit("input", value.name);
      },
      showErrors() {
        if (!this.required && this.moduleName !== "automatedTasks") {
          return false;
        }
        if (!this.selectedDepartment) {
          const msg = `Please select a ${this.field.label}`;
          this.$refs.input.setCustomValidity(msg);
          this.error = msg;
        } else {
          this.$refs.input.setCustomValidity('');
          this.error = null;
        }
        return !this.error;
      },
      // Will move this to store to make it generic when we get confirmation regarding department filed behaviour in company user form
      fetchDepartments() {
        if (this.departments?.length) {
          this.setDepartment();
          return;
        }
        http
        .get('/departments.json')
        .then((res) => {
          this.departments = res.data.companyDepartments;
          this.setDepartment();
        })
        .catch(() => {
          this.fetchDepartments();
          this.emitError(`Sorry, there was an error fetching company departments. Please refresh the page and try again.`);
        });
      },
      loadUserDept() {
        return http
        .get(`/current_company_users/curr_user_dept`)
        .then((res) => {
          this.userDepartment = res.data.currUserDept;
        })
        .catch(() => {
          this.emitError(`Sorry, there was an error loading this user department`);
        });
      },
      setDepartment() {
        if (this.userDepartment) {
          const matchingDepartment = this.departments.find((c) => c.name === this.userDepartment);
          if (matchingDepartment) {
            this.selectedDepartment = matchingDepartment;
            this.emitValue(matchingDepartment);
          }
        }
        if (this.value) {
          const matchingValue = this.departments.find((c) => c.name === this.value);
          if (matchingValue) {
            this.selectedDepartment = matchingValue;
            this.emitValue(matchingValue);
          }
        }
      },
      setNewDepartment(value) {
        this.selectedDepartment = value;
        this.emitValue(value);
      },
      removeDepartment() {
        if (this.value) {
          this.selectedDepartment = null;
          this.$emit('input', null);
        }
      },
    },
  };
</script>
