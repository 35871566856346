<template>
  <div>
    <help-center-header
      :is-basic-user-helpdesk="isBasicUserHelpdesk"
      :show-submit-request="false"
    >
      <div class="position-relative hc-text-header">
        <h1 class="mt-5 font-weight-bold help-center-title">
          Report an Issue
        </h1>
        <div class="mb-7 not-as-big">
          Technical issues, questions, suggestions - we're here for you.
        </div>
      </div>
    </help-center-header>
    <div class="pb-7 position-relative border-b-30 readable-length--large mx-auto">
      <div class="row">
        <div class="col-8 pr-0">
          <div class="box px-5 pt-5 mt-n6 box--natural-height pb-0 help-center-main-content">
            <pulse-loader
              v-if="loading"
              size="0.5rem"
              color="#0d6efd"
              loading
            />
            <div v-else-if="!isValidForm || !isValidWorkspace">
              <router-link
                :to="resetPath"
                class="text-secondary mr-4 float-right"
                data-tc-back
              >
                <i class="nulodgicon-arrow-left-c white mr-2"/>
                <span>Back to <strong>Ticket Portal</strong></span>
              </router-link>
              <div class="mt-5">
                <p>We're sorry but this form is not available any longer.</p>
                <p class="not-as-small">
                  In order to make a form available in your Help Center, log in and go to: <br> <span class="bg-light rounded">Help Desk</span> > <span class="bg-light rounded">Settings</span> > <span class="bg-light rounded">Custom Forms</span>.
                </p>
                <p class="not-as-small">
                  Select a form, toggle 'Help Center' and check <span class="bg-light rounded">Allow this form in the Help Center</span>. Save and voilà!
                </p>
              </div>
            </div>
            <div
              v-else
              class="box__inner"
            >
              <div class="d-flex align-items-center mt-n3 mb-4 justify-content-between">
                <div class="d-flex">
                  <span class="smallest text-muted">Help Desk Workspace:</span>
                  <multi-select
                    name="workspaces"
                    placeholder="Select Workspace"
                    select-label=""
                    deselect-label=""
                    track-by="name"
                    label="name"
                    :value="selectedWorkspace"
                    :allow-empty="true"
                    :multiple="false"
                    :options="workspacesWithPublicForms"
                    @select="(workspace) => selectWorkspace(workspace, true)"
                  />
                </div>
                <div 
                  class="d-flex align-items-start cursor-pointer" 
                  @click="$router.push(`/workspaces/${workspace.id}`);"
                >
                  <i class="nulodgicon-arrow-left-c white mr-2"/>
                  <span>Back</span>
                </div>
              </div>
              <div class="d-flex flex-column pl-0 mb-4">
                <h5 class="font-weight-normal">
                  What kind of issue are you experiencing?
                </h5>
                <div 
                  v-if="forms"
                  class="mb-n2 mx-n2 form-group"
                >
                  <label
                    v-for="form in forms"
                    :key="form.id"
                    class="border-300 py-2 px-3 mx-2 cursor-pointer help-center-custom-pill truncate"
                    :class="[form.id === selectedForm.id ? 'hc-bg-button' : 'bg-white text-dark']"
                    @click="selectForm(form)"
                  >
                    {{ form.name }}
                  </label>
                </div>
                <pulse-loader
                  v-else
                  size="0.5rem"
                  :color="helpCenterHyperlinksColor"
                  :loading="true"
                />
              </div>
              <div>
                <div
                  v-if="selectedForm"
                  class="rounded border-300 not-as-small p-3 bg-titan-white mb-5"
                >
                  Fill out this <strong>{{ selectedForm.name }}</strong> form
                  below as best as you can and one of our workspace agents
                  will get back to you shortly!
                </div>
                <section
                  v-if="forms"
                  class="help-center-form pb-5"
                >
                  <pulse-loader
                    v-if="loadingStatus"
                    size="0.5rem"
                    :color="helpCenterHyperlinksColor"
                    loading
                  />
                  <custom-form-viewer
                    v-show="!loadingStatus"
                    is-ticket-portal
                    modern-design-enabled
                    :form="selectedForm"
                    :company-module="'helpdesk'"
                    class="mt-3"
                  />
                </section>
              </div>
            </div>
          </div>
        </div>
        <div class="col text-right pl-0">
          <div class="mt-6 mx-n7 ">
            <img 
              class="help-center-image"
              src="~/images/help_center.png"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import http from 'common/http';
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import CustomFormViewer from "components/shared/custom_forms/custom_form_viewer.vue";
  import MultiSelect from 'vue-multiselect';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import permissionsHelper from 'mixins/permissions_helper';
  import { mapGetters, mapActions} from 'vuex';
  import HelpCenterHeader from './help_center_header.vue';

  export default {
    components: {
      CustomFormViewer,
      MultiSelect,
      PulseLoader,
      HelpCenterHeader,
    },
    mixins: [helpCenterPortalHelper, permissionsHelper],
    props: {
      helpCenterWorkspaces: {
        type: Array,
        default: () => ([]),
      },
      isValidWorkspace: {
        type: Boolean,
        default: false,
      },
      isBasicUserHelpdesk: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        forms: null,
        selectedForm: null,
        selectedWorkspace: null,
        isWorkspaceChanged: false,
        loading: true,
        isValidForm: true,
      };
    },
    computed: {
      ...mapGetters([
        'loadingStatus',
        'helpCenterBGColor',
        'helpCenterHyperlinksColor',
      ]),
      resetPath() {
        return this.selectedWorkspace ? `/workspaces/${this.selectedWorkspace.id}` : "/";
      },
      workspacesWithPublicForms() {
        return this.helpCenterWorkspaces.filter(w => w.publicFormsEnabled);
      },
    },
    mounted() {
      let workspace = this.workspacesWithPublicForms.find(w => w.id === parseInt(this.$route.params.workspace_id, 10));
      if (!this.isValidWorkspace || !workspace) {
        workspace = getWorkspaceFromStorage();
      }
      this.selectWorkspace(workspace, false);
    },
    methods: {
      ...mapActions(['fetchHelpCenterColors']),
      selectWorkspace(workspace, workspaceChanged) {
        this.isWorkspaceChanged = workspaceChanged;
        this.selectedWorkspace = workspace;
        setWorkspaceToStorage(workspace);
        this.fetchHelpCenterColors().then(() => {
          this.fetchCustomForms();
        });
      },
      selectForm(form) {
        if (form.id !== this.selectedForm.id) {
          this.selectedForm = form;
          this.$router.push(`/workspaces/${this.selectedWorkspace.id}/forms/${this.selectedForm.id}`);
        }
      },
      fetchCustomForms() {
        const params = {
          company_module: 'helpdesk',
          // 1- In case of portal it will fetch public custom forms
          // 2- In case of basic user it will fetch default forms of workspace
          portal: !this.isBasicUserHelpdesk,
          active: true,
        };
        this.forms = null;
        return http
          .get(`/custom_form_options.json`, { params })
          .then((res) => {
            const formId = parseInt(this.$route.params.id, 10);
            if (this.isWorkspaceChanged || (formId && res.data.find(f => f.id === formId))) {
              this.configFormsRoute(res.data);
            } else {
              this.isValidForm = false;
            }
            this.loading = false;
          }) 
          .catch((error) => {
            this.loading = false;
            this.emitError(`Sorry, there was an error loading the forms. ${error.response.data.message}.`);
          });
      },
      configFormsRoute(forms) {
        if (this.isWorkspaceChanged) {
          this.setDefaultForm(forms);
        } else {
          this.selectedForm = forms.find(f => f.id === parseInt(this.$route.params.id,10));
        }
        this.forms = forms;
        this.isWorkspaceChanged = false;
      },
      setDefaultForm(forms) {
        let formsCopy = [...forms];
        let defaultForm = forms.find(f => f.default);
        if (defaultForm) {
          formsCopy = forms.filter(item => item !== defaultForm);
          formsCopy.unshift(defaultForm);
        } else {
          [defaultForm] = formsCopy;
        }
        this.forms = formsCopy;
        this.selectForm(defaultForm);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .help-center-form {
    :deep(.btn-primary) {
      background-color: var(--hc-button-bg, $primary);
      border-color: var(--hc-button-bg, $primary);
      color: var(--hc-button-text, white);

      &:hover {
        background-color: var(--hc-button-hover-bg, shade-color($primary, 16%));
        border-color: var(--hc-button-hover-bg, shade-color($primary, 16%));
        color: var(--hc-button-text, white);
      }
    }
  }
</style>
